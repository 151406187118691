<template>
  <div>
    <vue-headful :title="pageTitle"/>
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px; text-transform: uppercase;"
      >{{ pageheading }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading/>
      </div>
      <div :class="{ 'visible': isLoading && emailTemplateItems.length === 0 }" id="body-content-area" style="overflow: hidden;">
        <div id="pagelayout" style="overflow: hidden;">
          <div>
            <div class="bottom-bar" style="display: flex; justify-content: flex-end" v-if="!isLoading" id="buttongroup">
              <div class="level">
                <div class="level-left">
                  <div class="field is-grouped">
                    <div class="control">
                      <a :class="['button is-accent', $hasPermissions(clientSession, ['EMAIL_TEMPLATES'], 2) ? '' : 'disabled']">
                        <span class="icon">
                          <i class="fal fa-plus-square"></i>
                        </span>
                        <span>
                          <router-link :to="{ name: $hasPermissions(clientSession, ['EMAIL_TEMPLATES'], 2) ? 'NewEmailTemplate' : null, params: {  }}"
                                      class="has-text-white">Add Template</router-link>
                        </span>
                      </a>
                    </div>
                    <div class="control">
                      <a :class="['button is-dark', $hasPermissions(clientSession, ['EMAIL_TEMPLATES'], 2) ? '' : 'disabled']" @click="confirmDelete" :disabled="!hasSelection || !$hasPermissions(clientSession, ['EMAIL_TEMPLATES'])">
                        <span class="icon">
                          <i class="fal fa-trash-alt"></i>
                        </span>
                        <span>Delete Template{{ hasPluralSelection ? 's' : '' }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Grid
              :style="{maxHeight: maxHeight+'px', height: maxHeight+'px', marginTop: '1rem'}"
              :filter="filter"
              :data-items="emailTemplateItems"
              :selected-field="selectedField"
              :sortable="true"
              :sort="sort"
              :filterable="false"
              :pageable="{
                ...pageable,
                pageSizes: pageSizes
              }"
              :page-size="pageSize"
              :skip="skip"
              :take="take"
              :total="totalRecords"
              :columns="columns"
              @selectionchange="onSelectionChange"
              @headerselectionchange="onHeaderSelectionChange"
              @filterchange="filterChange"
              @sortchange="sortChangeHandler"
              @pagechange="pageChangeHandler"
            >
              <template v-slot:editTemplate="{props}">
                <td>
                  <router-link class="bcc-color" :to="{ name: 'EditEmailTemplate', params: { id: `${props.dataItem.id}`, model: `${JSON.stringify(props.dataItem)}` }}">
                    {{props.dataItem.name}}
                  </router-link>
                </td>
              </template>
            </Grid>
          </div>

          <div id="delete-modal" class="modal is-active" v-if="showDeleteModal">
            <div class="modal-background" @click="showDeleteModal = false"></div>
            <div class="modal-card">
              <header class="modal-card-head has-bg-danger">
                <div class="modal-card-title has-bg-danger">Confirm Delete</div>
                <a class="delete" aria-label="close" @click="showDeleteModal = false"></a>
              </header>
              <section class="modal-card-body">
                <div class="content">
                  Type "<b>delete</b>" to confirm that you want to delete one or more items.
                </div>
                <div class="field">
                  <div class="control">
                    <input type="text"
                      class="input"
                      placeholder="type `delete` and then click confirm"
                      v-focus
                      v-model.trim="confirmationText"
                      @keydown.enter.prevent="deleteIfConfirmed"
                    >
                  </div>
                </div>
              </section>
              <footer class="modal-card-foot">
                <a class="button is-danger"
                   @click="deleteIfConfirmed"
                   :disabled="confirmationText !== 'delete'">
                  <span class="icon">
                    <i class="fal fa-trash-alt"></i>
                  </span>
                  <span>Confirm</span>
                </a>
                <a class="button" @click="showDeleteModal = false">Cancel</a>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { orderBy, filterBy } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'

let pageName = 'Email Template Management'

export default {
  components: {
    Loading,
    'Grid': Grid
  },
  data () {
    return {
      minResults: 0,
      maxHeight: 0,
      emailTemplates: [],
      isLoading: true,
      selectedRows: [],
      selectedRowValue: true,
      templateTypes: {
        AccountAccess: 'Account Access',
        Reporting: 'Reporting'
      },
      showDeleteModal: false,
      confirmationText: '',
      props: {
        grid: Grid,
        field: String,
        filterType: String,
        value: [String, Number, Boolean, Date],
        operator: String
      },
      skip: 0,
      take: 20,
      pageSize: 20,
      perPage: 10,
      page: 1,
      totalRecords: 0,
      pageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: true,
        previousNext: true
      },
      pageheading: pageName,
      staticColumns: [
        {
          field: 'id',
          title: 'Id',
          filterable: false,
          hidden: true
        },
        {
          field: 'name',
          title: 'Name',
          width: '320px',
          cell: 'editTemplate'
        },
        {
          field: 'type',
          title: 'Type',
          width: '130px',
          filterable: false
        },
        {
          field: 'updatedAt',
          title: 'Last Updated',
          cell: this.getShortDate,
          filterable: false
        }
      ],
      selectedField: 'selected',
      sort: [
        { field: 'name', dir: 'asc' }
      ],
      filter: {
        logic: 'and',
        filters: [
        ]
      }
    }
  },
  mounted () {
    let that = this
    this.$nextTick(() => {
      let elements = [
        '.has-text-centered.has-background-primary',
        '.columns.top-bar',
        '.top-bar-spacer',
        '.bottom-bar',
        '.bottom-bar-spacer'
      ]

      let func = async (results) => {
        let [numberOfResults, maxHeight] = results
        this.take = numberOfResults
        this.perPage = numberOfResults
        this.minResults = numberOfResults

        this.maxHeight = maxHeight - 40

        this.getTemplates()
      }

      that.getListHeights('body-content-area', 40, 79, elements, func.bind(this))

      window.addEventListener('resize', () => {
        that.getListHeights('body-content-area', 40, 79, elements, func.bind(that))
      })
    })
  },
  watch: {
    async $route (to, from) {
      let reload = await this.loadQueryParams(to.query)
      if (reload) {
        await this.goToPage(to.query.page, this.getTemplates)
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    lastpage () {
      return Math.ceil(this.totalRecords / this.perPage)
    },
    hasSelection () {
      return this.selectedRows.length > 0
    },
    hasPluralSelection () {
      return this.selectedRows.length > 1
    },
    emailTemplateItems: function () {
      if (this.emailTemplates.length === 0) {
        return []
      }
      let filtered = filterBy(this.emailTemplates.slice(this.skip, this.take + this.skip), this.filter)
      return orderBy(filtered, this.sort)
    },
    areAllSelected () {
      let selected = this.emailTemplates.findIndex(item => item.selected === false)
      let isUndefined = this.emailTemplates.findIndex(item => item.selected === undefined)

      return selected === -1 && isUndefined === -1
    },
    columns () {
      return [
        { field: 'selected', width: '28px', filterable: false, headerSelectionValue: this.areAllSelected },
        ...this.staticColumns
      ]
    },
    pageSizes () {
      let sizes = [this.minResults]
      let max = this.totalRecords
      let counter = this.minResults
      let min = this.minResults

      if (max !== 0 && min !== 0) {
        while (counter < max) {
          let size = counter + 10
          sizes.push(size > max ? max : size)
          counter += 10
        }
      }

      return sizes
    }
  },
  methods: {
    async refreshData () {
      await this.getTemplates()
    },
    async applyFilters () {
      this.goToPage(1, this.getTemplates)
    },
    loadQueryParams (query) {
      let reload = false
      return reload
    },
    async getTemplates () {
      this.pageSize = 20
      this.take = 20

      try {
        let response = {}
        let route = `/email-templates`
        response = await this.axios.get(route)

        const totalRecs = response.data.page.totalRecords
        this.totalRecords = totalRecs
        this.page = response.data.page.page

        this.emailTemplates = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    deleteIfConfirmed (event) {
      if (this.confirmationText === 'delete') {
        this.deleteSelected('/email-templates')
      }

      this.selectedRows = []

      this.emailTemplates = this.emailTemplates.map((item) => {
        return { ...item, selected: false }
      })
    },
    onHeaderSelectionChange (event) {
      let checked = event.event.target.checked
      Vue.set(this, 'emailTemplates', this.emailTemplates.map((item) => { return { ...item, selected: checked } }))

      if (checked) {
        this.selectedRows = this.emailTemplates
          .filter(function (e) {
            return e.selected === true
          })
          .map(function (i) {
            return i.id
          })
      } else {
        this.selectedRows = []
      }
    },
    onSelectionChange (event) {
      let checked = event.event.target.checked
      const id = event.dataItem.id
      if (checked) {
        this.selectedRows.push(id)
      } else {
        var idx = this.selectedRows.indexOf(id)
        if (idx > -1) {
          this.selectedRows.splice(idx, 1)
        }
      }
      Vue.set(event.dataItem, this.selectedField, event.dataItem[this.selectedField])
    },
    stripDate: function (dateString) {
      // 2016-02-24T00:11:00Z
      return dateString.substring(0, 10)
    },
    getShortDate: function (h, tdElement, props, listeners) {
      return h('td', {
        on: {
          click: function (e) {
            // Do nothing
          }
        }
      }, [this.stripDate(props.dataItem.updatedAt)])
    },
    pageChangeHandler: function (event) {
      this.skip = event.page.skip
      this.take = event.page.take
    },
    updatePagerState: function (key, value) {
      const newPageableState = Object.assign({}, this.pageable, { [key]: value })
      this.pageable = newPageableState
    },
    changeHandler: function (type, value) {
      this.updatePagerState(type, value)
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort
    },
    change: function (filter) {
      if (filter.syntheticEvent) { this.filter = filter.syntheticEvent.filter }
    },
    filterChange: function (e) {
      this.filter = e.filter
    },
    filterRender: function (h, defaultRendering, props, change) {
      return defaultRendering
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}
.k-checkbox:checked{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-pager-numbers .k-state-selected{
  background-color:  #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-grid th{
  font-weight: 600 !important;
}
.k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
  background-color: #CBE3B3;
  opacity: 0.8;
  font-weight: 600;
}
.k-list .k-item.k-state-selected{
   background-color:  #7fb942;
}
.k-list .k-item.k-state-selected:hover{
  background-color: #CBE3B3;
  color: #0d0c0c
}
.page-subtitle{
  color: #7fb942;
  font-weight: 600;
  font-size: 1em;
  margin-bottom: 8px;
}
.k-invalid-msg {
    display: inline-block;
}
.invalid {
    color: red;
}
.valid {
    color: green;
}
a.bcc-color{
  color: #7fb942 !important;
}
.bcc-color{
   color: #7fb942;
}
.k-grid td:first-child {
  color: transparent!important;
}

.k-grid th:first-child {
  color: transparent!important;
}

.visible {
  visibility: hidden;
}

.k-master-row > td:first-child {
  color: transparent!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
